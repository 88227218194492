

/* We set the size of the picture */
.product-list .product-image {
    max-height:250px;
    padding-top:5px;
    padding-bottom:5px;
    margin-right: 30px;
    flex: 0 0 auto;
}

/* We set the size of the picture */
.product-list .product-image > a {
    height:100%;
}

/* We set the size of the picture */
.product-list .product-image img {
    width: auto;
    height: 100%;
    max-height:240px;
    margin-left:auto;
    margin-right:auto;
}

/* We set shadow and margin for product list item */
.product-list {
    margin: 0 0 40px 0;
    padding: 0;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
}

/* We ensure that picture is verticaly aligned with content */
.product-list .grid-inner {
    display:flex;
    align-items: center;
}

/* We ensure that picture and content wrap for mobile */
/* picture takes full width and with small margin bottom */
@media (max-width: 767.98px) {
.product-list .product-image {
        width: 100%!important;
        margin-bottom: 20px;
}
.product-list .grid-inner {
      flex-wrap: wrap;
}
}

/* We display the content next to the picture */
.product-list .product-desc {
    padding-top:10px;
    padding-right:10px;
    padding-bottom: 0px;

    flex: 0 1 auto;
    align-self: stretch;

    display: flex;
    flex-direction: column;
    position:relative;
}
@media (max-width: 767.98px) {
.product-list .product-desc {
      padding-left:10px;
      width:100%;
}
}

/* We reduce description margin and ensure it takes possible space */
.product-list .product-desc div.description {
      flex-grow: 1;
      margin-bottom: 10px;
}
.product-list .product-desc div.description ul {
    margin-left:30px;
}

/* We hide the product description on mobile device */
@media (max-width: 767.98px) {
.product-list .product-desc div.description {
        /* display:none; */
}
}

/* We set flex and correct wrapping to full buttons bar and add to cart section */
.product-list .buttons,
.product-list .buttons .add-to-cart {
    display:flex;
    flex-wrap: wrap;
}

/* We set margins for details button and add to cart sectino */
.product-list .buttons .view-details,
.product-list .buttons .add-to-cart {
    margin:0px 30px 10px 0px;
}


